//

export default {
    app_info: {
        app_name: "team_members",
        app_id: "b86b7803-80f0-450f-8bd0-3dbd17a5ef6e",
        app_title: "Team Members",
        app_icon: "https://static.wixstatic.com/media/19e924_93861790942e4189856761049928822b~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_93861790942e4189856761049928822b~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members",
        review_url: "https://www.wix.com/app-market/add-review/b86b7803-80f0-450f-8bd0-3dbd17a5ef6e",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How can the Team Members app help you?",
                        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members#h_cdb6cb4dc5",
                    },
                    {
                        title: "2. How to Add or Remove Items?",
                        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members#h_c36a6788ad",
                    },
                    {
                        title: "3. How do I change colors?",
                        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members#h_a94649ee1e",
                    },

                ],
            },
        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Sales Countdown Timer",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Team Members",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};