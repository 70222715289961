export default {
  app_info: {
    app_name: "price_list",
    app_id: "8a3b48b8-89be-437a-ac89-883364021a78",
    app_title: "Price List",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-price-list/logo/price_list_logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9163892-how-to-use-price-list",
    review_url:
      "https://www.wix.com/app-market/add-review/8a3b48b8-89be-437a-ac89-883364021a78",
  },

  tutorial_main: {
    show: false,
    title: "📘 Getting started guide",
    description:
      "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
    url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Help Articles",
        articles: [
          {
            title: "1. How does Price List help you?",
            url: "https://help.marketpushapps.com/en/articles/9163892-how-to-use-price-list#h_a0ed3dca49",
          },
          {
            title: "2. How to Add or Remove Items?",
            url: "https://help.marketpushapps.com/en/articles/9163892-how-to-use-price-list#h_39d013e5ac",
          },
          {
            title: "3. How do I change colors?",
            url: "https://help.marketpushapps.com/en/articles/9163892-how-to-use-price-list#h_5395fd4771",
          },

        ],
      },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail:
          "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-fb-reviews-js-snippet/image-youtube-tut.png",
        description:
          "This video by Market Push Apps shows you how to easily display image hotspots app on your website. You can add images, videos, and HTML content to the hotspots. ",
        url: "https://www.youtube.com/watch?v=1234567",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Price List",

    negative_feedback_options: [{
        title: "Features do not work for me",
        message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
