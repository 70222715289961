<template>
  <section
    class="app-overview flex-center-center flex-v gap-24"
    v-show="(screen = 'feedback')"
  >
    <div class="main-context flex-v gap-2">
      <h2 class="feedback-title">Hi there! How was everything?</h2>
      <p class="description">Your review is incredible valuable to us, and we would love to hear how this app has helped you! ❤️</p>
    </div>

    <div class="review-switching-option flex gap-6 input-group">
      <label
        v-for="option in reviewOptions"
        :for="'reviewOption_' + option.value"
        :class="{ active: option.value === selected_FeedbackOption }"
        class="radio-input-wrapper flex-center-center flex-v gap-8"
        :key="option.value"
      >
        <input
          :id="'reviewOption_' + option.value"
          name="reviewOption"
          type="radio"
          :value="option.value"
          :checked="option.value === selected_FeedbackOption"
          v-model="selected_FeedbackOption"
          @input="emitFeedbackValue($event.target.value)"
        />

        <img :src="getOptionIcon(option.iconName)" :alt="option.iconName" />
        <div class="radio-icon-title">
          {{ option.title }}
        </div>
      </label>
    </div>
    <div class="bottom-description">Please choose your experience with us</div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { watch } from "vue";

const emit = defineEmits(["feedbackChange", "openProvideScreen"]);

import iconReviewTerrible from "@/assets/Icons/review/icon-review-terrible.svg";
import iconReviewBad from "@/assets/Icons/review/icon-review-bad.svg";
import iconReviewGood from "@/assets/Icons/review/icon-review-good.svg";
import iconReviewAmazing from "@/assets/Icons/review/icon-review-amazing.svg";

const selected_FeedbackOption = ref("");
const reviewOptions = [
  {
    title: "Terrible",
    value: "terrible",
    iconName: "icon-review-terrible",
  },
  {
    title: "Bad",
    value: "bad",
    iconName: "icon-review-bad",
  },
  {
    title: "Good",
    value: "good",
    iconName: "icon-review-good",
  },
  {
    title: "Amazing",
    value: "amazing",
    iconName: "icon-review-amazing",
  },
];

function getOptionIcon(iconName) {
  switch (iconName) {
    case "icon-review-terrible":
      return iconReviewTerrible;
    case "icon-review-bad":
      return iconReviewBad;
    case "icon-review-good":
      return iconReviewGood;
    case "icon-review-amazing":
      return iconReviewAmazing;
    default:
      return "";
  }
}

async function emitFeedbackValue(val) {
  const option = reviewOptions.find((option) => {
    return option.value == val;
  });
  emit("feedbackChange", option);
}

async function openFeedbackProvideScreen() {
  emit("openProvideScreen", selected_FeedbackOption.value);
}

watch(selected_FeedbackOption, (newValue) => {
  console.log("Selected Feedback Option changed:", newValue);
  openFeedbackProvideScreen();
});
</script>

<style lang="scss" scoped>
@import "./feedbackScreens.scss";
</style>
