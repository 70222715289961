// 
export default {
    app_info: {
        app_name: "bulk_add_to_cart",
        app_id: "1382922e-ac81-4b38-baee-1816d2b6e331",
        app_title: "Bulk Add To Cart",
        app_icon: "", // TBD
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9048334-how-to-use-bulk-add-to-cart",
        review_url: "https://www.wix.com/app-market/add-review/1382922e-ac81-4b38-baee-1816d2b6e331",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How can the Bulk Add To Cart app help you?",
                        url: "https://help.marketpushapps.com/en/articles/9048334-how-to-use-bulk-add-to-cart#h_587dccea3d",
                    },
                    {
                        title: "2. How to add Bulk Add To Cart to your site",
                        url: "https://help.marketpushapps.com/en/articles/9048334-how-to-use-bulk-add-to-cart#h_719efa9703",
                    },
                    {
                        title: "3. How to change the number of products provided in the Bundle",
                        url: "https://help.marketpushapps.com/en/articles/9048334-how-to-use-bulk-add-to-cart#h_7ac0004bae",
                    },
                    {
                        title: "4. How to change the number of products provided in the Bundle",
                        url: "https://help.marketpushapps.com/en/articles/9048334-how-to-use-bulk-add-to-cart#h_b857370703",
                    },

                ],
            },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Sales Countdown Timer",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Bulk Add To Cart",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};