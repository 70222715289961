// Import wix block apps
import countdown_event from "./apps/countdown_event";
import countdown_sales from "./apps/countdown_sales";
import countdown_wedding from "./apps/countdown_wedding";
import footer_templates from "./apps/footer_templates";
import google_sheets_cell_value from "./apps/google_sheets_cell_value";
import hero_templates from "./apps/hero_templates";
import horizontal_collapse_tabs from "./apps/horizontal_collapse_tabs";
import image_hotspots from "./apps/image_hotspots";
import number_counter from "./apps/number_counter";
import nutrition_label from "./apps/nutrition_label";
import products_counter from "./apps/products_counter";
import qr_code from "./apps/qr_code";
import restaurant_menu from "./apps/restaurant_menu";
import tabs_vertical_collapse from "./apps/tabs_vertical_collapse";
import tutorial from "./apps/tutorial";
import under_construction_page from "./apps/under_construction_page";
import vimeo_gallery from "./apps/vimeo_gallery";
import youtube_gallery from "./apps/youtube_gallery";
import price_list from "./apps/price_list";
import team_members from "./apps/team_members";
import bulk_add_to_cart from "./apps/bulk_add_to_cart";
import pet_adoption from "./apps/pet_adoption";
import loan_calculator from "./apps/loan_calculator";
import reviews_section from "./apps/reviews_section";
import facebook_gallery from "./apps/facebook_gallery";
import pie_chart from "./apps/pie_chart";
import donut_chart from "./apps/donut_chart";
import pricing_plans from "./apps/pricing_plans";
import line_chart from "./apps/line_chart";
import tiktok_feed from "./apps/tiktok_feed";
import area_chart from "./apps/area_chart";
import column_chart from "./apps/column_chart";
import bar_chart from "./apps/bar_chart";
import pricing_table from "./apps/pricing_table";
import funnel_chart from "./apps/funnel_chart";
import pyramid_chart from "./apps/pyramid_chart";
import timeline_chart from "./apps/timeline_chart";
import statistics_card from "./apps/statistics_card";

const apps = {
  image_hotspots,
  qr_code,
  countdown_event,
  countdown_wedding,
  countdown_sales,
  restaurant_menu,
  horizontal_collapse_tabs,
  footer_templates,
  hero_templates,
  number_counter,
  products_counter,
  vimeo_gallery,
  tabs_vertical_collapse,
  under_construction_page,
  google_sheets_cell_value,
  tutorial,
  nutrition_label,
  youtube_gallery,
  price_list,
  team_members,
  bulk_add_to_cart,
  pet_adoption,
  loan_calculator,
  reviews_section,
  facebook_gallery,
  pie_chart,
  donut_chart,
  pricing_plans,
  line_chart,
  tiktok_feed,
  area_chart,
  column_chart,
  bar_chart,
  pricing_table,
  funnel_chart,
  pyramid_chart,
  timeline_chart,
  statistics_card,
  // Add more wix apps as needed
};

export default apps;
