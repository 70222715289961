<script setup>
import { useIntercom } from "@homebaseai/vue3-intercom";
import { ref } from "vue";
const intercom = useIntercom();
let hideDescription = ref(true);

function openChatWidget() {
  hideDescription.value = false;
  setTimeout(() => {
    intercom.showNewMessage("I have a question: --> ");
  }, 1500);
}
</script>

<template>
  <div class="support-banner-container flex-v gap-0">
    <div class="support-banner flex-v flex-center-center gap-16">
      <h1 class="card-title">Support Center</h1>
      <h3 class="card-description">
        Ask us anything using our chat widget. We're here to help!
      </h3>
      <base-button class="btn-white" @click="openChatWidget()"
        >Ask a Question</base-button
      >
    </div>
    <div
      class="additional-info"
      :class="{ 'hide-description': hideDescription.valueOf() == true }"
    >
      <div class="info-text">
        When you contact us, please provide a detailed explanation of your
        issue, along with screenshots, if they are relevant. Also, it would be
        great if you can share with us a link to a published page where we can
        see the app.
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.support-banner {
  padding: 40px 24px 56px 24px;
  background: linear-gradient(79deg, $gray-800 -72.71%, #00178a 134.42%);
  border-radius: 16px;
  text-align: center;
  color: $white;
  z-index: 11;

  .card-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
  }
  .card-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }
}

.hide-description {
  max-height: 0px !important;
  opacity: 0;
  margin-top: -72px !important;
}

.additional-info {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  margin-top: -24px;
  transition: 0.2s all;
  text-align: center;

  background: $yellow-50;

  padding: 48px 24px 24px 24px;
  border-radius: 0 0 16px 16px;

  .info-text {
    font-size: 13.6px;
    color: $yellow-300;
    margin: auto;
    max-width: 600px;
  }
}
</style>
