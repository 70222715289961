<template>
  <div class="bar flex-center-between gap-12 flex-wrap">
    <div class="app-info flex-center gap-12">
      <div
        v-if="appInfo.app_icon && appInfo.app_icon !== ''"
        class="img-holder"
      >
        <img :src="appInfo.app_icon" :alt="appInfo.app_name" class="app-icon" />
      </div>
      <div class="flex-v flex-start gap-0">
        <h2 class="app-info-title">{{ appInfo.app_title }}</h2>
        <div class="provider-info">
          By
          <span @click="openDeveloperPage" class="app-info-provider-link"
            >MarketPushApps</span
          >
        </div>
      </div>
    </div>
    <div class="button-container">
      <base-button
        model="btn-white faq-button"
        iconName="state/icon-menu-support"
        iconSide="left"
        :showIcon="true"
        @click="openFAQSite()"
      >
        Open FAQ
      </base-button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  appInfo: {
    type: Object,
    required: true,
  },
  mainLinks: {
    type: Object,
    required: true,
  },
});

function openDeveloperPage() {
  window.open(
    "https://www.wix.com/app-market/developer/marketpushapps",
    "_blank"
  );
}
function openFAQSite() {
  window.open(props.mainLinks?.faq_url, "_blank");
}
</script>

<style lang="scss" scoped>
.img-holder {
  border-radius: 4.8px;
}
.app-info {
  .app-icon {
    width: 60px;
    height: 60px;
    aspect-ratio: 1/1;
    border-radius: 4.8px;
  }
}
.faq-button {
  animation: pulse-animation 2s infinite;
  &:hover {
    animation: none;
  }
}

.button-container {
  .icon {
    color: $primary-500;
  }
}

.provider-info {
  color: $gray-600;
  font-size: 14px;
  font-weight: 400;

  .app-info-provider-link {
    &:hover {
      color: $primary-500;
      cursor: pointer;
    }
    color: $primary-400;
  }
}

.app-info-title {
  color: $black-HE;
  font-size: 18px;
  font-weight: 600;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
  }
}
</style>
