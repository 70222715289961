export default {
  app_info: {
    app_name: "pie_chart",
    app_id: "5617d88f-5fd3-477a-9a97-161fceaba681", // TBD
    app_title: "Pie Chart Pro",
    // TBD
    app_icon:
      "https://static.wixstatic.com/media/19e924_2b6ea0b2851045fe80f73bca9218dba7~mv2.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9300881-how-to-use-pie-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/5617d88f-5fd3-477a-9a97-161fceaba681", // TBD
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "After installing, follow the initial guidelines to easily set up the pie-chart widget and customize it with your preferred design. Effortlessly configure the pie-chart widget by adhering to these initial guidelines, tailoring it to seamlessly match your preferred design.",
    url: "https://help.marketpushapps.com/en/articles/9300881-how-to-use-pie-chart#:~:text=use%20Pie%20Chart-,How%20to%20use%20Pie%20Chart,-Updated%20over%20a",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Pie Chart",
        articles: [
          {
            title: "1. Setting the type of the pie chart",
            url: "https://help.marketpushapps.com/en/articles/9300881-how-to-use-pie-chart#:~:text=Step%201%3A%20Setting%20the%20type%20of%20the%20pie%20chart",
          },
          {
            title: "2. Setting partition count and its data.",
            url: "https://help.marketpushapps.com/en/articles/9300881-how-to-use-pie-chart#:~:text=Step%202%3A%20Setting%20partition%20count%20and%20its%20data.",
          },
          {
            title: "3. Change the design of the chart",
            url: "https://help.marketpushapps.com/en/articles/9300881-how-to-use-pie-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: false, //TBD
    videos: [
      {
        title: "How to use Pie Chart",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
        url: "https://www.youtube.com/watch?v=123456", // TBD
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Pie Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, that is --> ",
      },
      {
        title: "User flow is more complex",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "Widgets have some issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
