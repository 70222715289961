export default {
  app_info: {
    app_name: "funnel_chart",
    app_id: "e5b653ea-bd3c-4f77-88ed-4b9a137240d8",
    app_title: "Funnel Chart",

    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-charts-frontend/funnel-chart/Assets/Logo/logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/e5b653ea-bd3c-4f77-88ed-4b9a137240d8",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "The Funnel Chart Widget is a versatile tool that allows you to create and customize Funnel Chart Widget for your website.",
    url: "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Funnel Chart",
        articles: [
          {
            title: "1. How to Add Funnel Chart",
            url: "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart#:~:text=1.%20How%20to%20Add%20Funnel%20Chart",
          },
          {
            title: "2. How To Add Represent your data",
            url: "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart#:~:text=2.%20How%20To%20Add%20Represent%20your%20data",
          },
          {
            title: "3. How To Change the Style of Your Chart.",
            url: "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart#:~:text=4.%20How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9414738-how-to-use-funnel-chart#:~:text=5.%20Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: false, //TBD
    videos: [
      {
        title: "How to use Donut Chart",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
        url: "https://www.youtube.com/watch?v=123456", // TBD
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Funnel Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, that is --> ",
      },
      {
        title: "User flow is more complex",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "Widgets have some issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
