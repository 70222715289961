// DONE

export default {
  app_info: {
    app_name: "qr-code",
    app_id: "22ecb27b-ca00-412d-a03b-634d708587d4",
    app_title: "QR Code",
    app_icon:
      "https://static.wixstatic.com/media/19e924_ade54973eeed4791bfc139f581495fed~mv2.webp",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9150309-how-to-use-qr-code",
    review_url:
      "https://www.wix.com/app-market/add-review/22ecb27b-ca00-412d-a03b-634d708587d4",
  },

  tutorial_main: {
    show: false,
    title: "📘 Getting started guide",
    description:
      "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
    url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to add Qr Code",
            url: "https://help.marketpushapps.com/en/articles/9150309-how-to-use-qr-code#h_500d1b0b86",
          },
          {
            title: "2. How to change QR Code Generation URL",
            url: "https://help.marketpushapps.com/en/articles/9150309-how-to-use-qr-code#h_6ea0be0321",
          },
          {
            title: "3. How to enable / disable items from the QR Code widget",
            url: "https://help.marketpushapps.com/en/articles/9150309-how-to-use-qr-code#h_d4dc99e901",
          },
          {
            title: "4. How to change the QR Code Color & Style",
            url: "https://help.marketpushapps.com/en/articles/9150309-how-to-use-qr-code#h_c1d2c23076",
          },
          {
            title: "5. How to change Color & Style of the elements",
            url: "https://help.marketpushapps.com/en/articles/9150309-how-to-use-qr-code#h_765cedbe11",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to show QR Code on your website",
        thumbnail:
          "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/QR%20Code_yt_thumbnail.jpg",
        description:
          "This video by Market Push Apps shows you how to easily display a QR code widget on your website using the app.",
        url: "https://youtu.be/oxu0L5GcjRA?t=20",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "QR Code",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
