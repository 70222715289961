export const storage = {
  getItem(key, fallbackValue) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.log(e);
      return fallbackValue;
    }
  },
  setItem(key, value, fallbackValue) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      return fallbackValue;
    }
  },
  removeItem(key, value) {
    try {
      localStorage.removeItem(key, value);
    } catch (e) {
      console.log("Error removing item from localStorage");
    }
  },
};
