//

export default {
    app_info: {
        app_name: "pricing_plans",
        app_id: "17954fc2-b398-4b06-b55f-35caef03cc44", // TBD
        app_title: "Pricing Plans",
        // TBD
        app_icon: "https://static.wixstatic.com/media/19e924_93861790942e4189856761049928822b~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_93861790942e4189856761049928822b~mv2.webp", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates",
        review_url: "https://www.wix.com/app-market/add-review/e85dc71f-a952-4d89-b590-c265e2cc6d75", // TBD
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Pricing Plans offers you different kinds of pricing plan templates. Choose from six templates with monthly and yearly options. Easy to use and keeps your pricing looking professional.",
        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Pricing Plans",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Pricing Plans",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};