import { createRouter, createWebHashHistory } from "vue-router";
import SupportView from "@/views/SupportView.vue";
import FeedbackView from "@/views/FeedbackView.vue";
import DefaultView from "@/views/DefaultView.vue";

const routes = [
  {
    path: "/",
    name: "DefaultView",
    component: DefaultView,
  },
  {
    path: "/feedback/:stage",
    name: "feedback",
    component: FeedbackView,
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
