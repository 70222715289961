// Image Hotspots app details and configurations

export default {
    app_info: {
        app_name: "products-counter",
        app_id: "53904432-9461-4d68-88c7-3d17e6fe581b",
        app_title: "eCom Counter",
        app_icon: "https://static.wixstatic.com/media/19e924_1aaf537452704a3ebc83390654df0cac~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_1aaf537452704a3ebc83390654df0cac~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter",
        review_url: "https://www.wix.com/app-market/add-review/53904432-9461-4d68-88c7-3d17e6fe581b",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. What to use eCom Counter For",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_b16cd83e6c",
                },
                {
                    title: "2. How does the app work",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_eec4511597",
                },
                {
                    title: "3. Where can I best place the eCom Counter",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_bbe0f4c57d",
                },
                {
                    title: "4. How to edit Text Color and Background Color",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_f037393c9d",
                },
                {
                    title: "5. How often does the data refresh",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_a879b93f48",
                },
                {
                    title: "6. How to change and use Templates",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_f25344054f",
                },
                {
                    title: "7. Can I use my own Emojis in the text",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_ba8a69e2f8",
                },
                {
                    title: "8. How to add Brands to my Products",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_2c82306bd2",
                },
                {
                    title: "9. How to show the number of orders that my store has",
                    url: "https://help.marketpushapps.com/en/articles/8738275-how-to-use-ecom-counter#h_aedb06aad0",
                },

            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use eCom Counter",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "eCom Counter",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};