<script setup>
import { onMounted, ref } from "vue";
import { useAppStore } from "./stores";
import { useIntercom } from "@homebaseai/vue3-intercom";
import { useRouter } from "vue-router";
import { storage } from "./stores/localStorage";
import registeredApps from "../src/services/registered-apps";

const router = useRouter();

import LoadingScreen from "@/components/LoadingScreen/LoadingScreen.vue";
import FooterBar from "@/components/Footer/FooterBar.vue";

const appStore = useAppStore();
const intercom = useIntercom();
const appInitialStatus = ref("loading");

async function fetchData() {
  // http://localhost:8080/?instance=171c0c38-b2f2-4be4-9d11-21320ba900bb&app=image_hotspots&page=feedback-mood
  // instance --> instanceId: "171c0c38-b2f2-4be4-9d11-21320ba900bb"
  // app --> appName: "image_hotspots"
  // page --> reqPage: feedback-mood
  // page --> reqPage: feedback-negative
  // page --> reqPage: support
  let currentUserStatus = "success_user-support";
  const urlParams = new URL(location.href).searchParams;
  const [instanceId, appName, reqPage] = ["instance", "app", "page"].map(
    (param) => urlParams.get(param)
  );

  const isUserAvailable = instanceId && appName;
  if (!isUserAvailable || !registeredApps[appName]) {
    currentUserStatus = "error_no-user";
  }
  try {
    await appStore.updateAppData(instanceId, appName);
  } catch (error) {
    currentUserStatus = "error_no-user";
  }
  setupIntercom();

  if (currentUserStatus == "error_no-user") {
    return currentUserStatus;
  }

  return "success_user-" + reqPage || "default";
}

function setupIntercom() {
  let intercom_widget;
  try {
    intercom_widget = appStore.currentApp.intercom_widget;
  } catch (error) {
    intercom_widget = {
      show: true,
      appName: "User or App Not Found",
    };
  }
  intercom.boot({
    app_id: process.env.VUE_APP_INTERCOM_TOKEN,
    // user_id: appStore.instanceId,
    // instance_id: appStore.instanceId,
    app: intercom_widget.appName,
    alignment: "right",
    vertical_padding: 24,
  });

  // intercom.show();
}

async function navigateForReqPage(state, reviewedStage = "false") {
  if (state != "error_no-user" && reviewedStage) {
    state = "support";
  }

  switch (state) {
    // Show no user/default page
    case "error_no-user":
      router.push("/");
      break;

    // Show support page(auto open intercom)
    case "success_user-support":
      router.push("/support");
      break;

    // Show feedback page with mood selection
    case "success_user-feedback-mood":
      router.push("/feedback/select-mood");
      break;

    // Show feedback page with negative review
    case "success_user-feedback-negative":
      router.push("/feedback/negative-review");
      break;

    // Show support page(no auto open intercom)
    default:
      router.push("/support");
      break;
  }
}

onMounted(async () => {
  const requestedPage = await fetchData();
  let currentApp = appStore.currentApp?.app_info?.app_name;
  let isUserReviewed = await storage.getItem(
    `is-user-reviewed_${currentApp}`,
    "false"
  );
  navigateForReqPage(requestedPage, isUserReviewed);
  appInitialStatus.value = "success";
});
</script>

<template>
  <div>
    <LoadingScreen v-if="appInitialStatus == 'loading'" />
    <!-- <nav v-if="appInitialStatus == 'success'">
      <router-link to="/">Default</router-link> |
      <router-link to="/support">Support</router-link> |
      <router-link to="/feedback/select-mood">Feedback</router-link>
    </nav> -->
    <div class="page-view" v-if="appInitialStatus == 'success'">
      <router-view />
      <FooterBar v-if="appInitialStatus == 'success'" />
    </div>
  </div>
</template>

<style lang="scss">
#app {
  height: 100vh;
  .page-view {
    flex: 1;
    position: relative;
    z-index: 0;
    width: 100%;
    min-height: 100vh;
  }
  &.initial-background {
    background: $primary-200 !important;
    background-color: $primary-200 !important;
  }
  .page-container {
    overflow: auto;
    flex: 1;
  }
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $white;
  gap: 20px;
}
</style>
