// DONE

export default {
    app_info: {
        app_name: "number-counter",
        app_id: "511dbcad-37ea-43ef-85e3-4bb755ab2c5d",
        app_title: "Numbers & Stats Counter",
        app_icon: "https://static.wixstatic.com/media/6cc087_d67b20a7cb2545d4a44cc26c97401eda~mv2.png/v1/fill/w_54,h_54,al_c,q_85,usm_0.66_1.00_0.01/6cc087_d67b20a7cb2545d4a44cc26c97401eda~mv2.webp", //TBC
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq",
        review_url: "https://www.wix.com/app-market/add-review/511dbcad-37ea-43ef-85e3-4bb755ab2c5d",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to change the icon template",
                        url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq#h_f6d2392b32",
                    },
                    {
                        title: "2. How to remove the watermark branding",
                        url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq#h_a4dd867779",
                    },
                    {
                        title: "3. How to make the widget responsive on Wix Editor (default)",
                        url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq#h_1a9cafd80a",
                    },
                    {
                        title: "4. How to make the widget responsive on Wix Studio / EditorX.",
                        url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq#h_275c81b619",
                    },
                    {
                        title: "5. How to customise the colors of default icons",
                        url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq#h_8057d1609d",
                    },
                    {
                        title: "6. How to replace the icons in the app with your own",
                        url: "https://help.marketpushapps.com/en/articles/8455831-number-counter-faq#h_b3dae3b500",
                    },
                ],
            },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Numbers & Stats Counter",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Numbers & Stats Counter",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};