// DONE

export default {
    app_info: {
        app_name: "loan_calculator",
        app_id: "tbd", // TBD
        app_title: "Loan Calculator",
        app_icon: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-ce-loan_calculator/loan-calculator-logo.png", // TBD
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator", 
        review_url: "https://www.wix.com/app-market/add-review/tbd",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Loan Calculator to your site",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_72ca0ee3e3",
                    },
                    {
                        title: "2. How to make the widget responsive",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_a50bb66288",
                    },
                    {
                        title: "3. How to set the default values in the calculator",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_103a904e3d",
                    },
                    {
                        title: "4. How to change the range for the calculator values",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_149ea7df42",
                    },
                    {
                        title: "5. How is the interest calculated",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_586187466d",
                    },



                ],
            },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Loan Calculator",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Loan Calculator",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};