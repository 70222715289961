// Image Hotspots app details and configurations

export default {
    app_info: {
        app_name: "restaurant-menu",
        app_id: "ee589104-c395-4f61-b151-978b57012154",
        app_title: "Restaurant Menu",
        app_icon: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-ce-restaurant-menu/logo/mkp-restaurant-menus-logo.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus",
        review_url: "https://www.wix.com/app-market/add-review/ee589104-c395-4f61-b151-978b57012154",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How does Restaurant Menu help you?",
                    url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus#h_928a3b67c9",
                },
                {
                    title: "2. How to change or use a different template",
                    url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus#h_6372f74ba4",
                },
                {
                    title: "3. How to modify the number of sections in the menu",
                    url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus#h_ded19385f3",
                },
                {
                    title: "4. I need more than 4 sections, or a different layout",
                    url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus#h_597a62890b",
                },
                {
                    title: "5. How to Add or Remove Items from a menu section?",
                    url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus#h_5a02cbdf04",
                },
                {
                    title: "6. How do I change colors?",
                    url: "https://help.marketpushapps.com/en/articles/9099881-how-to-use-restaurant-menus#h_268d28d9e5",
                },
            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Restaurant Menu",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Restaurant Menu",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};