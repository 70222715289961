//

export default {
    app_info: {
        app_name: "sales_countdown_timer",
        app_id: "6617f599-7708-43f3-9b39-6a8b05423a12",
        app_title: "Sale Countdown Timer",
        app_icon: "", // TBD
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer",
        review_url: "https://www.wix.com/app-market/add-review/6617f599-7708-43f3-9b39-6a8b05423a12",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Event Countdown Timer",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_44a30f0a2a",
                    },
                    {
                        title: "2. How to change the Template",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_69b8f05e6c",
                    },
                    {
                        title: "3. How to change an Image",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_54f06aea11",
                    },
                    {
                        title: "4. How to change Color & Style",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_4bad4e5316",
                    },
                    {
                        title: "5. How to change the counter date",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_d66827f21e",
                    },
                    {
                        title: "6. What happens at end of the counter when it reaches 00:00:00:00 and how to customise it",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_3f43b02ddd",
                    },

                ],
            },
            {
                collection_name: "Help Articles",
                articles: [{
                        title: "How to add colors without a color picker?",
                        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
                    },
                    {
                        title: "How to upload my own image?",
                        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
                    },
                    {
                        title: "How to add HTML content to Popup message?",
                        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
                    },
                    {
                        title: "How can I Disable/Hide the popup message for a specific hotspot?",
                        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Sales Countdown Timer",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Sales Countdown Timer",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};