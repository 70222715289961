<template>
  <button
    class="flex-center-center default-btn"
    :class="[
      model,
      {
        'loading-state': checkLoadingState,
        'right-side-icon': iconSide == 'right',
      },
      size,
    ]"
    :disabled="disable"
    v-wave
  >
    <div
      v-if="checkLoadingState"
      class="spinner"
      :class="{ 'spinner-hide': !checkLoadingState }"
    ></div>
    <div
      v-if="iconSide === 'left' && showIcon && !checkLoadingState"
      class="icon"
      :style="{
        '-webkit-mask-image': backgroundImage,
        'mask-image': backgroundImage,
        '-webkit-mask-size': 'cover',
        ' mask-size': 'cover',
      }"
    ></div>
    <div class="btn-content flex-center">
      <slot>
        {{ state instanceof Object ? state.replaceText : text }}
      </slot>
    </div>
    <div
      v-if="iconSide === 'right' && showIcon && !checkLoadingState"
      class="icon"
      :style="{
        '-webkit-mask-image': backgroundImage,
        'mask-image': backgroundImage,
        '-webkit-mask-size': 'cover',
        ' mask-size': 'cover',
      }"
    ></div>
  </button>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  text: String,
  model: {
    type: String,
    default: "main",
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    default: "icon-user-admin",
  },
  iconSide: {
    type: String,
    default: "left",
  },
  disable: {
    type: Boolean,
    default: false,
  },
  state: {
    type: [String, Object],
    default: "default",
  },
  size: {
    type: String,
    default: "M",
  },
});

const disable = ref(props.disable);
const state = ref(props.state);
const iconName = ref(props.iconName);
const iconSide = ref(props.iconSide);
const size = ref(props.size);

const checkLoadingState = computed(() => {
  return (
    (typeof state.value === "string" && state.value === "loading") ||
    (typeof state.value === "object" && state.value.stateLabel === "loading")
  );
});

const backgroundImage = computed(() => {
  return "url(" + require("@/assets/Icons/" + iconName.value + ".svg") + ")";
});
</script>

<style lang="scss" scoped>
.default-btn {
  gap: 6px;
  background: $primary-300;
  color: $white;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: unset;

  &.right-side-icon {
    .icon {
      margin: 0;
      margin-right: -4px;
    }
  }

  &.M {
    padding: 3px 24px;
  }
  &.W {
    width: max(100% - 24px, 240px);
  }
  &.S {
    min-height: 24px;
    border-radius: 12px;
    font-weight: 300;
    padding: 2px 12px;
    .icon {
      width: 14px;
      height: 14px;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: $white;
    margin-left: -4px;
  }

  &.sub {
    background: transparent;
    color: $primary-300;
    border: 1px solid $primary-50;
    .icon {
      background-color: $primary-300;
    }

    &:hover {
      border: 1px solid $primary-100;
      background: #{$primary-100}33;
    }
    &:disabled {
      background: transparent;
      border: 1px solid $gray-300;
    }
    .spinner {
      background: conic-gradient(#{$primary-300}33, $primary-300);
    }
    .spinner::after {
      background: $gray-100;
    }
  }
  &.sub-red {
    background: transparent;
    color: $red;
    border: 1px solid $red;
    .icon {
      background-color: $red;
    }

    &:hover {
      background: $red-50;
    }
    &:disabled {
      background: transparent;
      border: 1px solid $gray-300;
    }
    .spinner {
      background: conic-gradient(#{$red}33, $red);
    }
    .spinner::after {
      background: $red-50;
    }
  }

  &.text {
    background: transparent;
    color: $primary-300;
    padding-inline: 12px;
    .icon {
      background-color: $primary-300;
      margin-left: -2px;
    }

    &:hover {
      background: $primary-50;
    }
    &:disabled {
      background: transparent;
    }

    .spinner {
      background: conic-gradient(#{$primary-300}33, $primary-300);
    }
    .spinner::after {
      background: $white;
    }
  }
  &.text-link {
    background: transparent;
    color: $primary-200;
    padding-inline: 12px;
    padding: 0 !important;
    min-height: 18px;
    font-weight: 300;
    text-transform: none;
    text-decoration: unset;
    .icon {
      background-color: $primary-200;
      margin-left: -2px;
    }

    &:hover {
      text-decoration: underline;
      filter: unset;
    }
    &:disabled {
      background: transparent;
    }

    .spinner {
      background: conic-gradient(#{$primary-300}33, $primary-300);
    }
    .spinner::after {
      background: $white;
    }
  }
  &.sub-gold {
    gap: 8px;
    background: transparent;
    color: $gold;
    min-height: 28px;
    border: 1px solid #{$gold}33;
    .icon {
      background-color: $gold;
    }

    &:hover {
      border: 1px solid #{$gold}66;
      background: #{$gold}33;
    }
    &:disabled {
      background: transparent;
      border: 1px solid $gray-300;
    }
    .spinner {
      background: conic-gradient(#{$primary-300}33, $primary-300);
    }
    .spinner::after {
      background: $white;
    }
  }
  &.sub-red {
    background: $red-50;
    color: $red;

    border: 1px solid #{$red}33;
    .icon {
      background-color: $red;
    }

    &:hover {
      border: 1px solid #{$red}66;
      background: #{$red}33;
      .spinner {
        background: conic-gradient(#{$red}33, $red);
      }
      .spinner::after {
        background: #f4cdc6;
      }
    }
    &:disabled {
      background: transparent;
      border: 1px solid $gray-300;
    }
    .spinner {
      background: conic-gradient(#{$red}33, $red);
    }
    .spinner::after {
      background: #fff8f7;
    }
  }
  &.red {
    background: $red;
    color: $white;

    .spinner {
      background: conic-gradient(#{$white}33, $white);
    }
    .spinner::after {
      background: $red;
    }
  }
  &.blue {
    background: $primary-200;
    color: $white;

    .spinner {
      background: conic-gradient(#{$white}33, $white);
    }
    .spinner::after {
      background: $primary-200;
    }
  }
  &.pink {
    background: $pink;
    color: $white;

    .spinner {
      background: conic-gradient(#{$white}33, $white);
    }
    .spinner::after {
      background: $pink;
    }
  }
  &.orange {
    background: $orange;
    color: $white;

    .spinner {
      background: conic-gradient(#{$white}33, $white);
    }
    .spinner::after {
      background: $orange;
    }
  }

  &.pink-sub {
    background: $pink-50;
    color: $pink;
    .icon {
      background-color: $pink;
    }
    &:hover {
      filter: brightness(98%);
    }

    .spinner {
      background: conic-gradient(#{$pink}33, $pink);
    }
    .spinner::after {
      background: $pink-50;
    }
  }
  &.primary-sub {
    background: $primary-25;
    color: $primary-200;
    padding-inline: 12px;
    .icon {
      background-color: $primary-200;
      margin-left: -4px;
    }

    &:hover {
      background: $primary-50;
      filter: brightness(100%);
    }
    .spinner {
      background: conic-gradient(#{$primary-25}33, $primary-25);
    }
    .spinner::after {
      background: $primary-200;
    }
  }
  &.black {
    border: 1px solid $gray-400;
    background: transparent;
    color: $black;
    .icon {
      background-color: $black;
      margin-left: -4px;
    }

    &:hover {
      border: 1px solid $gray-600;
      color: $gray-900;
      filter: brightness(90%);
    }
    .spinner {
      background: conic-gradient(#{$primary-25}33, $primary-25);
    }
    .spinner::after {
      background: $primary-200;
    }
  }

  &:disabled {
    background: $gray-300;
    color: $black-disabled;
    cursor: not-allowed;
    pointer-events: none;
    .icon {
      background-color: $black-disabled;
    }
    .spinner {
      background: conic-gradient(#{$black-disabled}33, $black-disabled);
    }
    .spinner::after {
      background: $gray-100;
    }
  }

  &.loading-state {
    padding: 0 18px 0 12px;
  }
  .spinner-hide {
    opacity: 0 !important;
    width: 0;
    height: 0;
    box-sizing: border-box;
    transform: scale(0.5) translateX(-6px);
    margin-right: -6px;
  }
  .spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: conic-gradient(#{$white}33, $white);
    position: relative;
    animation: spin 1.4s linear infinite;
    transform: translateZ(0);
  }

  .spinner::after {
    background: $primary-300;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
</style>
