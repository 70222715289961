export default {
  app_info: {
    app_name: "facebook_gallery",
    app_id: "8836ac38-6399-42ea-aa82-963a283ea3d5", // TBD
    app_title: "Facebook Gallery",
    // TBD
    app_icon:
      "https://static.wixstatic.com/media/19e924_18169424bd9c44b5bee1ab87d16d4da5~mv2.jpg/v1/fill/w_108,h_108,al_c,q_80,usm_0.66_1.00_0.01/19e924_18169424bd9c44b5bee1ab87d16d4da5~mv2.webp",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery",
    review_url:
      "https://www.wix.com/app-market/add-review/8836ac38-6399-42ea-aa82-963a283ea3d5", // TBD
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "The ultimate Facebook Video Gallery app! Add videos from multiple pages, choose from three video sizes, and enjoy seamless pagination. Perfect for captivating your audience with stunning video content!",
    url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to add Facebook Gallery to your site",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_b3e2c7a734",
          },
          {
            title: "2. How to connect Facebook videos to your Gallery",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_09fcc448bd",
          },
          {
            title: "3. How can I make videos smaller/bigger",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_0381247aab",
          },
          {
            title: "4. How to make the widget responsive on Mobile and Desktop",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_0cfc276b6a",
          },
          {
            title: "5. My Mobile version style modifications are not applied",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_3274854707",
          },
          {
            title: "6. How do I modify the background color",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_3f578f7ceb",
          },
          {
            title: "7. How can I add Pagination",
            url: "https://help.marketpushapps.com/en/articles/9296488-how-to-use-facebook-video-gallery#h_43150ed491",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: false, //TBD
    videos: [
      {
        title: "How to use Facebook Gallery",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
        url: "https://www.youtube.com/watch?v=123456", // TBD
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Facebook Gallery",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, that is --> ",
      },
      {
        title: "User flow is more complex",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "Widgets have some issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
