// Line Chart app details and configurations

export default {
  app_info: {
    app_name: "mkp-dev-area-chart",
    app_id: "88031483-c17b-4929-8f4d-6c4b43ec92a5",
    app_title: "Area Chart",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-charts-frontend/area-chart-frontend/logo/logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/88031483-c17b-4929-8f4d-6c4b43ec92a5",
  },

  storage: {
    collection_name: "mkp-dev-area-chart",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Area Chart Widget is a versatile tool that allows you to create and customize area charts for your website. You can easily configure the chart with different line types and stroke styles, feed data through various methods including API and manual input, and adjust the chart's design to match your site. Follow the detailed guide to get the most out of this powerful widget.",
    url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Area Chart",
        articles: [
          {
            title: "1. How to add Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=1.%20How%20to%20Add%20Area%20Chart",
          },
          {
            title: "2. How To Represent Your data in Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=2.%20How%20To%20Represent%20Your%20data.",
          },
          {
            title: "3. How To Change the Style of Your Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
      // {
      //   collection_name: "Help Articles",
      //   articles: [
      //     {
      //       title: "How to add colors without a color picker?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
      //     },
      //     {
      //       title: "How to upload my own image?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
      //     },
      //     {
      //       title: "How to add HTML content to Popup message?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
      //     },
      //     {
      //       title:
      //         "How can I Disable/Hide the popup message for a specific hotspot?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
      //     },
      //   ],
      // },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Area Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
