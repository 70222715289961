import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import App from "./App.vue";

import VueIntercom from "@homebaseai/vue3-intercom";
import VWave from "v-wave";

import BaseIcon from "@/components/shared/BaseIcon.vue";
import BaseIconOriginal from "@/components/shared/BaseIconOriginal.vue";
import BaseButton from "@/components/shared/Buttons/BaseButton.vue";
import BaseButtonIcon from "@/components/shared/Buttons/BaseButtonIcon.vue";

const app = createApp(App);

app.component("BaseIcon", BaseIcon);
app.component("BaseIconOriginal", BaseIconOriginal);
app.component("base-button", BaseButton);
app.component("base-button-icon", BaseButtonIcon);

app.use(VWave);
app.use(VueIntercom);
app.use(createPinia());
app.use(router);

app.mount("#app");
