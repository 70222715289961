<script setup></script>

<template>
  <div class="loading-screen flex-v flex-center-center gap-36">
    <div class="loading-spinner"></div>
  </div>
</template>

<style lang="scss" scoped>
.loading-screen {
  max-width: 100vw;
  max-height: 100dvh;
  background: $primary-200;
  color: $white;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-spinner {
  width: 54px;
  height: 54px;
  border: 5px solid $gray-300;
  border-bottom-color: $primary-500;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
