<template>
  <div
    class="icon"
    :style="{
      ...originalIconMixin(iconName, size),
      '-webkit-mask-image': backgroundImage,
      'mask-image': backgroundImage,
      '-webkit-mask-size': 'contain',
      'mask-size': 'contain',
      '-webkit-mask-repeat': 'no-repeat',
      'mask-repeat': 'no-repeat',
      '-webkit-mask-position': 'center',
      width: size + 'px',
      height:
        this.styles.L_Bdg_Template === 'bdg_5' ||
        this.styles.L_Bdg_Template === 'bdg_6'
          ? size * 0.5 + 'px'
          : size + 'px',
    }"
  ></div>
</template>

<script>
export default {
  name: "BaseIconOriginal",
  props: {
    styles: {
      type: Object,
      default() {
        return {
          C_Bdg_IconFB_Type: "icon",
          L_Bdg_Icon_Size: 32,
        };
      },
    },
    iconName: {
      type: String,
      default: "actions/icon-actions-add",
    },
    size: {
      type: [Number, String],
      default: "16",
    },
  },
  methods: {
    originalIconMixin(name, size) {
      const url = require(`@/assets/Icons/${name}.svg`);
      return {
        width: size + "px",
        height:
          this.styles.L_Bdg_Template == "bdg_5" ||
          this.styles.L_Bdg_Template == "bdg_6"
            ? size * 0.5 + "px"
            : size + "px",
        content: `url(${url})`,
      };
    },
  },
  computed: {
    backgroundImage() {
      return (
        "url(" + require("../../assets/Icons/" + this.iconName + ".svg") + ")"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  -webkit-mask-size: cover;
  mask-size: cover;
}
</style>
