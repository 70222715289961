<script setup>
import BaseIcon from "../shared/BaseIcon.vue";

defineProps({
  title: {
    type: String,
    required: true,
  },
  articles: {
    type: Array,
    required: true,
  },
});

function openLink(link) {
  window.open(link, "_blank");
}
</script>

<template>
  <div class="card flex-v gap-20">
    <h2 class="card-title">{{ title }}</h2>
    <div class="article-links flex-v gap-12">
      <div
        v-for="(article, index) in articles"
        :key="index"
        @click="openLink(article.url)"
        class="article-link flex-start gap-8"
      >
        <BaseIcon
          size="20"
          color="gray-800"
          iconName="state/icon-state-document"
          class="icon-document"
        />
        <div class="flex-1">{{ article.title }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  flex: 1;
  width: 100%;
  min-width: 500px;
  max-width: calc(50% - 30px);

  border-radius: 16px;
  border: 1px solid $gray-200;
  padding: 28px 32px 32px 32px;

  transition: 0.1s all;
}

.card-title {
  color: $gray-800;
  font-size: 18px;
  font-weight: 700;
}

.article-links {
  color: $gray-800;
  font-size: 16px;
  transition: 0.2s all;
}

.article-link {
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    color: $primary-500;
    .icon {
      background: $primary-500;
    }
  }
}

.icon-document {
  margin-top: 2px;
}

@media screen and (max-width: 1180px) {
  .card {
    max-width: 100%;
    margin-top: -24px;
  }
}
</style>
