// DONE

export default {
    app_info: {
        app_name: "tutorial",
        app_id: "cc725afa-3e7e-4610-8de5-9435fe4efb0b",
        app_title: "Steps Layout",
        app_icon: "https://static.wixstatic.com/media/33caa7_99f7615d7236449a802e3bdb49bf4594~mv2.jpg/v1/fill/w_54,h_54,al_c,q_80,usm_0.66_1.00_0.01/33caa7_99f7615d7236449a802e3bdb49bf4594~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app",
        review_url: "https://www.wix.com/app-market/add-review/cc725afa-3e7e-4610-8de5-9435fe4efb0b",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How can the Tutorial app help you",
                    url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app#h_e806de8021",
                },
                {
                    title: "2. How to add the app to the site",
                    url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app#h_1966043bfc",
                },
                {
                    title: "3. How to select the number of Steps / I need more steps in my tutorial",
                    url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app#h_256eed8a37",
                },
                {
                    title: "4. How to change a Step Type",
                    url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app#h_ab6b8e419c",
                },
                {
                    title: "5. How to add an image",
                    url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app#h_20993329eb",
                },
                {
                    title: "6. How to change content / style",
                    url: "https://help.marketpushapps.com/en/articles/8843345-how-to-use-tutorial-app#h_e7b7bd4f0f",
                },


            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Steps Layout",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Steps Layout",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};