import { defineStore } from "pinia";
import apps from "../services/registered-apps";
// import { getStorageValue, setStorageValue } from "./storageApi";

export const useAppStore = defineStore("store", {
  state: () => ({
    instanceId: null,
    currentApp: null,
    app_review_status: {
      review_stage: "not-asked",
    },
  }),
  getters: {
    getCurrentApp: (state) => {
      return state.currentApp;
    },
  },
  actions: {
    updateAppData(instanceId, appName) {
      this.instanceId = instanceId;
      this.currentApp = apps[appName];
    },

    // ** Comment due to not using APIs **
    // /**
    //  * @function
    //  * @description Retrieves the app review status from storage and initializes it if not present.
    //  * @param {string} instanceId - Instance ID (defaults to stored value).
    //  * @returns  {Promise<Object>} - The app review status object.
    //  */
    // async getReviewStatus(instanceId = this.instanceId) {
    //   const collectionName = this.currentApp.storage.collection_name;
    //   try {
    //     // Retrieve the app review status string from storage.
    //     const statusString = await getStorageValue(
    //       instanceId,
    //       collectionName,
    //       "app_review_status"
    //     );

    //     // Parse the app review status string as JSON.
    //     const appReviewStatus = statusString ? JSON.parse(statusString) : null;

    //     // If app review status is present, return it.
    //     if (appReviewStatus) return appReviewStatus;

    //     // Initialize app review status if not present and store it in storage.
    //     const defaultStatusString = JSON.stringify(this.app_review_status);
    //     await setStorageValue(
    //       instanceId,
    //       collectionName,
    //       "app_review_status",
    //       defaultStatusString
    //     );
    //     // console.log('⬆️ updateReviewStatus ~ app_review_status[first time]: ', this.app_review_status);

    //     // Return the initialized app review status.
    //     return this.app_review_status;
    //   } catch (error) {
    //     // Handle and log any errors that may occur during the retrieval or initialization process.
    //     console.error("getReviewStatus ~ error: ", error);

    //     // Return the default app review status in case of an error.
    //     return this.app_review_status;
    //   }
    // },

    // /**
    //  * @function
    //  * @description Updates the review stage in the app review status and stores the updated status in storage.
    //  * @param {string} new_stage - The new review stage ('not-asked', '1st-time-asked', '2nd-time-asked', '2rd-time-asked', 'never', 'reviewed').
    //  * @param {string} [instanceId] - Instance ID (defaults to stored value).
    //  * @returns {Promise<void>} A Promise that resolves when the review stage is successfully updated.
    //  */
    // async updateReviewStage(new_stage, instanceId = this.instanceId) {
    //   const collectionName = this.currentApp.storage.collection_name;

    //   try {
    //     // Update the review stage in the review status.
    //     this.app_review_status.review_stage = new_stage;

    //     // Convert the updated review status to a string and Update the review status in storage.
    //     const string = JSON.stringify(this.app_review_status);
    //     await setStorageValue(
    //       instanceId,
    //       collectionName,
    //       "app_review_status",
    //       string
    //     );
    //     console.log(
    //       "⬆️ updateReviewStage ~ app_review_status[ReviewStage]: ",
    //       new_stage,
    //       this.app_review_status
    //     );
    //   } catch (error) {
    //     // Handle and log any errors that may occur during the update process.
    //     console.error("⬆️ updateReviewStage ~ error: ", error);
    //   }
    // },
  },
});
