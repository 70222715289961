<template>
  <footer class="footer flex-center-center">
    <div class="footer-text-container flex gap-40 flex-wrap">
      <div
        @click="openLink('https://www.youtube.com/@marketpushapps/videos')"
        class="footer-item"
      >
        Youtube Channel
      </div>
      <div
        @click="openLink('https://www.marketpushapps.com')"
        class="footer-item"
      >
        Website
      </div>
      <div
        @click="
          openLink('https://www.wix.com/app-market/developer/marketpushapps')
        "
        class="footer-item"
      >
        More MKP Apps
      </div>
    </div>
  </footer>
</template>

<script setup>
function openLink(link) {
  window.open(link, "_blank");
}
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid $gray-200;
  background-color: $background;
  text-align: center;
}
.footer-text-container {
  padding: 24px 60px 80px 60px;
  width: 100%;
  min-width: 300px;
  max-width: 1400px;
  margin: 0 auto;
  .footer-item {
    color: $black-HE;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: $primary-500;
    }
  }
}
</style>
