<template>
  <div class="common-container feedback-view-container">
    <AppInfoBar
      :appInfo="app_info"
      :mainLinks="main_links"
      class="app-info-bar"
    />

    <div class="feedback-container flex-center-center flex-v gap-60">
      <div class="feedback-content-container flex-v flex-center-center">
        <FeedbackSelectMood
          @feedbackChange="feedbackChange"
          @openProvideScreen="openProvideScreen"
          v-show="feedbackStage == 'select-mood'"
        />
        <ReviewScreenBad
          @closeReview="closeReview"
          v-show="feedbackStage == 'negative-review'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import FeedbackSelectMood from "@/components/Feedback/FeedbackSelectMood.vue";
import ReviewScreenBad from "@/components/Feedback/ReviewScreenBad.vue";
import AppInfoBar from "@/components/Support/AppInfoBar.vue";
import { storage } from "@/stores/localStorage";
import { useAppStore } from "@/stores";
import { computed } from "vue";

import { useRoute, useRouter } from "vue-router";
const appStore = useAppStore();
const route = useRoute();
const router = useRouter();
let currentApp = appStore.currentApp.app_info.app_name;
console.log("currentApp", currentApp);

const reviewLink = appStore.currentApp.main_links.review_url;

function closeReview() {
  router.push("/support");
}
function feedbackChange(val) {
  if (val.value == "terrible" || val.value == "bad") {
    storage.setItem(`is-user-reviewed_${currentApp}`, true, false);
  } else {
    storage.setItem(`is-user-reviewed_${currentApp}`, false, false);
  }
}
function openProvideScreen(mood) {
  const isMoodBad = mood == "bad" || mood == "terrible";
  if (isMoodBad) {
    router.push("negative-review");
  } else {
    window.location.href = reviewLink;
  }
}

const feedbackStage = computed(() => {
  return route.params.stage || "select-mood";
});

const app_info = appStore.currentApp.app_info;
const main_links = appStore.currentApp.main_links;
</script>

<style lang="scss" scoped>
.feedback-view-container {
  min-height: calc(100vh - 136px);
}
.feedback-container {
  max-width: 1344px;
  min-width: 300px;
  flex: 1;

  background: $gray-100;
  margin-inline: auto;
  border-radius: 16px;
  margin-bottom: 60px;
}

.app-info-bar {
  margin-bottom: 40px;
  top: 0px;
}
</style>
