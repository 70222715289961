<template>
  <div class="statistic-card flex-center-between gap-0">
    <div class="body-content flex-v flex--center gap-24">
      <div class="flex-v gap-12">
        <div class="flex-v gap-0">
          <div class="card-label">{{ videoData.title }}</div>
          <div
            class="card-label-sub channel-link flex-center gap-6"
            @click="openMainChannelLink"
          >
            <BaseIconOriginal
              iconName="brands/icon-brands-youtube"
              :size="16"
            />
            Market Push Apps
          </div>
        </div>
        <div class="card-description">{{ videoData.description }}</div>
      </div>

      <div class="action-buttons flex-center gap-6">
        <base-button
          class="btn-white"
          :showIcon="true"
          iconName="state/icon-preview-play"
          @click="openReviewsTut"
        >
          Play Video Tutorial
        </base-button>
      </div>
    </div>

    <div class="image" @click="openReviewsTut">
      <div class="black-overlay"></div>
      <div class="play-btn-wrapper flex-center-center">
        <BaseIconOriginal
          iconName="brands/icon-brands-youtube"
          :size="56"
          class="play-btn"
        />
      </div>

      <img :src="videoData.thumbnail" alt="how-to-add-facebook-reviews" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      videoTutorialLink: "https://youtu.be/bLAG-0a-L2c?t=36",
      channelLink: "https://www.youtube.com/@marketpushapps/videos",
    };
  },
  methods: {
    openPage() {
      this.$router.push(this.routePath);
    },
    openMainChannelLink() {
      window.open(this.channelLink);
    },
    openReviewsTut() {
      window.open(this.videoData.url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.statistic-card {
  padding: 12px 24px 12px 12px;
  min-height: 180px;
  background-color: $gray-100;
  border: 1px solid $gray-100;
  border-radius: 16px;
  overflow: hidden;
  min-width: 500px;
  flex: 1;
  flex-wrap: wrap-reverse;
  position: relative;
}
.channel-link {
  padding: 2px 6px;
  transform: translateX(-6px) translateY(-2px);
  cursor: pointer;
  width: fit-content;
  border-radius: 6px;
  transition: 0.3s;
  &:hover {
    background: $red-100;
  }
}
.image {
  max-width: 360px;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  margin: 12px 0px 12px 24px;
  cursor: pointer;
  border: 1px solid $gray-100;
  z-index: 1;
  transition: 0.2s;

  img {
    object-fit: cover;
    height: 100%;
  }
  .black-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    transition: 0.3s;
  }
  .play-btn-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    .play-btn {
      transition: 0.3s;
    }
  }
  &:hover {
    scale: 1.01;
    .play-btn {
      scale: 1.1;
      filter: brightness(1.2);
    }
    .black-overlay {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.btn-white {
  background: transparent !important;
  color: $black-HE;
  border: 1px solid $black-HE !important;
  font-weight: 500;
  &:hover {
    background: $gray-300 !important;
  }
}

.body-content {
  padding: 16px 12px 24px 24px;
  flex: 1;
  max-width: 660px;
  .card-label {
    font-weight: 700;
    font-size: 24px;
    color: $black-HE;
  }
  .card-description {
    font-size: 16px;
    color: $black-HE;
    font-weight: 400;
  }
  .card-label-sub {
    font-weight: 400;
    font-size: 12x;
    color: $black-HE;
  }
}

.bg-pattern {
  position: absolute;
  left: 52%;
  bottom: -221%;
  z-index: 0;
  transform: rotate(27deg);
  animation: roundRotate 200s infinite;
}

@keyframes roundRotate {
  50% {
    transform: rotate(180deg);
  }
}
</style>
