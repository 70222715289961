<template>
  <div class="common-container flex-v gap-60">
    <AppInfoBar :appInfo="app_info" :mainLinks="main_links" />
    <SupportCard />
    <div
      v-if="tutorial_videos.show"
      class="tutorial-card-container flex-v gap-60"
    >
      <CardYoutubeTut
        v-for="videoData in tutorial_videos.videos"
        :key="videoData.title"
        :videoData="videoData"
      />
    </div>
    <div class="article-card-container">
      <div v-if="main_tutorial.show">
        <GettingStartedCard :mainTutorialData="main_tutorial" />
      </div>

      <template v-if="tutorial_articles.show">
        <ArticleCard
          v-for="articles_collection in tutorial_articles.articles_collections"
          :key="articles_collection.collection_name"
          :title="articles_collection.collection_name"
          :articles="articles_collection.articles"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from "@/stores";
import ArticleCard from "@/components/Support/ArticleCard.vue";
import SupportCard from "@/components/Support/SupportCard.vue";
import AppInfoBar from "@/components/Support/AppInfoBar.vue";
import CardYoutubeTut from "@/components/Support/CardYoutubeTut.vue";
import GettingStartedCard from "@/components/Support/GettingStartedCard.vue";
const appStore = useAppStore();

const app_info = appStore.currentApp.app_info;
const main_tutorial = appStore.currentApp.tutorial_main;
const tutorial_articles = appStore.currentApp.tutorial_articles;
const tutorial_videos = appStore.currentApp.tutorial_videos;

const main_links = appStore.currentApp.main_links;
</script>

<style lang="scss" scoped>
.article-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px;
}

.common-container {
  flex: 1;
  overflow-y: auto;
}
</style>
