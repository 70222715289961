<template>
  <div class="app-overview flex-v gap-40">
    <div class="flex-center-center flex-v gap-12">
      <div class="screen-bad-title">What went wrong?</div>

      <div class="flex-center-center flex-v gap-2">
        <p class="screen-bad-sub-title">
          We are sorry the experience wasn't at its best!
        </p>
        <p class="screen-bad-description">
          This is the first version of the app, and we're open to feedback.
        </p>
        <p class="screen-bad-description">
          Please share your concerns, and help us improve the next release. 😇
        </p>
      </div>
    </div>
    <div class="review-card-stack flex-v gap-12">
      <div
        v-for="option in feedback_options"
        :key="option.title"
        @click="openIntercom(option.message)"
        class="review-action-btn flex-center-between gap-6"
      >
        <div class="flex-center gap-6">
          <BaseIcon
            iconName="state/icon-state-idea-bulb"
            size="18"
            color="primary-200"
            v-if="option.title == 'Other'"
          />
          {{ option.title }}
        </div>
        <BaseIcon
          iconName="navigation/icon-navigation-right-arrow"
          size="24"
          color="gray-800"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useIntercom } from "@homebaseai/vue3-intercom";
import { useAppStore } from "@/stores";

const appStore = useAppStore();
const intercom = useIntercom();
const feedback_options =
  appStore.currentApp.intercom_widget.negative_feedback_options;

const emit = defineEmits(["closeReview"]);

function openIntercom(message) {
  intercom.showNewMessage(message);
  emit("closeReview", true);
}
</script>

<style lang="scss" scoped>
@import "./feedbackScreens.scss";

.app-overview {
  padding: 32px 40px 40px 40px;
}

.screen-bad-title {
  color: $gray-800;
  text-align: center;
  font-family: $default-font;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.screen-bad-sub-title,
.screen-bad-description {
  color: $gray-800;
  text-align: center;
  font-family: $default-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
