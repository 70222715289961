export default {
  app_info: {
    app_name: "tiktok_feed",
    app_id: "cc9ef405-8075-4f7b-930c-9a23f6c33584",
    app_title: "TikTok Feed Pro",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-ce-ratings-bar/assets/Logo_tiktok_feed_icon.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro",
    review_url:
      "https://www.wix.com/app-market/add-review/cc9ef405-8075-4f7b-930c-9a23f6c33584",
  },

  tutorial_main: {
    show: false,
    title: "📘 Getting started guide",
    description:
      "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
    url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to add TikTok Feed",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_9a97aeb858",
          },
          {
            title: "2. How to connect TikTok Account",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_d12a8ce118",
          },
          {
            title: "3. How to show/hide User Profile and change layout",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_be24ae7f11",
          },
          {
            title: "4. How to change Video Card layout",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_2b8770fb0b",
          },
          {
            title:
              "5. How to show/hide TikTok Video Player and change Popup design",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_8b88e8631d",
          },
          {
            title: "6. How to change Color & Style of the elements",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_284c6bd055",
          },
        ],
      },
      {
        collection_name: "Help Articles",
        articles: [
          {
            title: "How to disconnect/change connected account?",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_02965cdea4",
          },

          {
            title: "How to deal with TikTok connection problems?",
            url: "https://help.marketpushapps.com/en/articles/9612951-how-to-use-tiktok-feed-pro#h_f54b2d2fb0",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "TikTok Feed",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
