// DONE

export default {
    app_info: {
        app_name: "footer_templates",
        app_id: "3d0023a7-061a-4476-8577-7bb51a1c82b1",
        app_title: "Footer Templates",
        app_icon: "https://static.wixstatic.com/media/19e924_216e1105c0774407b663ede3fbae9d2a~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_216e1105c0774407b663ede3fbae9d2a~mv2.webp", // TBC
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8876034-how-to-use-footer-templates",
        review_url: "https://www.wix.com/app-market/add-review/3d0023a7-061a-4476-8577-7bb51a1c82b1",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Footer Templates to your site",
                        url: "https://help.marketpushapps.com/en/articles/8876034-how-to-use-footer-templates#h_74f4a86a9c",
                    },
                    {
                        title: "2. How to add my own links",
                        url: "https://help.marketpushapps.com/en/articles/8876034-how-to-use-footer-templates#h_55e5057b17",
                    },
                    {
                        title: "3. How to add my own social network links",
                        url: "https://help.marketpushapps.com/en/articles/8876034-how-to-use-footer-templates#h_8e61564e90",
                    },
                    {
                        title: "4. How to customize Whatsapp icon to open a conversation with my own phone number",
                        url: "https://help.marketpushapps.com/en/articles/8876034-how-to-use-footer-templates#h_81a2ba8790",
                    },
                    {
                        title: "5. How to change background color",
                        url: "https://help.marketpushapps.com/en/articles/8876034-how-to-use-footer-templates#h_4a62c00689",
                    },

                ],
            },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Footer Templates",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Footer Templates",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};