<script setup>
const props = defineProps({
  mainTutorialData: {
    type: Object,
    required: true,
  },
});

function openTutorial() {
  window.open(props.mainTutorialData.url, "_blank");
}
</script>

<template>
  <div class="card flex-v gap-24">
    <div class="card-content-container flex-v gap-12">
      <div class="card-title">{{ mainTutorialData.title }}</div>
      <div class="card-description">{{ mainTutorialData.description }}</div>
    </div>
    <div>
      <base-button
        class="btn-white"
        @click="openTutorial"
        :showIcon="true"
        iconName="navigation/icon-navigation-right-bold"
        iconSide="right"
      >
        Start Tutorial
      </base-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  border-radius: 16px;
  border: 1px solid $gray-200;
  display: flex;
  padding: 32px;
  width: 610px;
  min-width: 610px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

@media (max-width: 1242px) {
  .card {
    width: 100%;
  }
}

.card-title {
  color: $gray-800;
  font-family: $default-font;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card-description {
  color: $gray-800;
  font-family: $default-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.article-links {
  list-style-type: none;
  padding: 0;
  color: $gray-800;
  font-family: $default-font;
}

.article-links li {
  &:hover {
    .icon {
      background: $primary-500;
    }
  }
}

.article-links li a {
  color: #333;
  text-decoration: none;
}

.article-links li a:hover {
  color: $primary-500 !important;
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  transition: margin 0.3s ease;
}
</style>
