// DONE

export default {
    app_info: {
        app_name: "google-sheets-cell-value",
        app_id: "9fe86b9f-454f-492c-87e6-435e03308bcb",
        app_title: "Google Sheets Cell Value",
        app_icon: "https://static.wixstatic.com/media/19e924_2279d7bb37bf4d368b499f7b6468a1a8~mv2.png/v1/fill/w_54,h_54,al_c,q_85,usm_0.66_1.00_0.01/19e924_2279d7bb37bf4d368b499f7b6468a1a8~mv2.webp", // TBC
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq",
        review_url: "https://www.wix.com/app-market/add-review/9fe86b9f-454f-492c-87e6-435e03308bcb",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How can Google Sheets Cell Values help you?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_e8091ba03c",
                },
                {
                    title: "2. How to add it on my site?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_b63b9b3e3b",
                },
                {
                    title: "3. How to change the Connected Google Sheet?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_2b807ab8d1",
                },
                {
                    title: "4. How to change the Google Sheet Cell?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_8aa19f7a29",
                },
                {
                    title: "5. How to use the cell value as placeholder text?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_3988df2093",
                },
                {
                    title: "6. How to edit the style of text?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_790020e528",
                },
                {
                    title: "7. How long does it to refresh my cell with a new value? Is it slow?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_2c142e27ba",
                },
                {
                    title: "8. My Cell Value is not displayed correctly or in real-time?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_134a631057",
                },
                {
                    title: "9. Can I use Velo with this app?",
                    url: "https://help.marketpushapps.com/en/articles/8554120-google-sheets-cell-value-faq#h_3db8bbe600",
                },

            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Google Sheets Cell Value",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Google Sheets Cell Value",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};